import React from 'react';

const NotFoundPage = () => (
  <div>
    <h1>Not found</h1>
    <p>Sorry – page does not exist.</p>
  </div>
);

export default NotFoundPage;
